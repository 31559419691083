import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { Player } from "./Player";

function App() {
  const { id } = useParams<{ id: string }>();
  const [date] = useState(new Date());
  const [offset] = useState(
    id ? Number(id) - 1 : date.getTimezoneOffset() / 60
  );
  const [isPlaying, setIsPlaying] = useState(
    offset !== -1 ? date.getUTCMinutes() < 1 : true
  );
  const audioRef = useRef<HTMLAudioElement>(null);
  console.log("This page was loaded at ", date.toISOString());
  const DEBUG = false;

  useEffect(() => {
    const interval = setInterval(() => {
      if (audioRef.current) {
        const recentDate = new Date();
        if (!isPlaying) {
          DEBUG && console.log("syncing");
          const currentTime =
            recentDate.getMinutes() * 60 + recentDate.getSeconds();
          audioRef.current.currentTime = currentTime;

          if (currentTime < 60) {
            audioRef.current.play();
            setIsPlaying(true);
          }
        }

        if (isPlaying) {
          DEBUG && console.log(audioRef.current.currentTime / 60);
        }

        // We should reload the page to load the new track if the seconds
        // from a new date are lower than the seconds of when the page was
        // first loaded.
        if (
          recentDate.getMinutes() * 60 + recentDate.getSeconds() <
          date.getMinutes() * 60 + date.getSeconds()
        ) {
          window.location.reload();
        }
      }
    }, 1000);

    const canvas = document.getElementById("canvas");
    if (isPlaying && canvas) {
      canvas.classList.add("isPlaying");
    } else {
      canvas?.classList.remove("isPlaying");
    }

    return () => clearInterval(interval);
  }, [DEBUG, date, isPlaying]);

  const handleOnPlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleOnStop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className={`app ${isPlaying && "playing"}`}>
      <div className={isPlaying ? "overlayPlaying" : "overlay"}>
        +{offset + 1}
      </div>
      <div className="controls">
        <header>
          <span>Fip</span>+{offset + 1}
        </header>
        {!isPlaying && (
          <button onClick={handleOnPlay} aria-label={"Play"} className="play" />
        )}
        {isPlaying && (
          <button onClick={handleOnStop} aria-label={"Stop"} className="stop" />
        )}
      </div>
      <Player audioRef={audioRef} date={date} offset={offset} />
    </div>
  );
}

export default App;
