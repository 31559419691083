export const Player = (props: {
  audioRef: any;
  date: Date;
  offset: number;
}) => {
  const { audioRef, date, offset } = props;
  const adjustedOffset = offset + 1;
  const file =
    date.getUTCHours() - adjustedOffset < 0
      ? 24 + date.getUTCHours() - adjustedOffset
      : date.getUTCHours() - adjustedOffset;

  return offset !== -1 ? (
    <audio
      id="audio"
      preload="auto"
      ref={audioRef}
      src={`https://fip-plus.s3.amazonaws.com/${file}.mp3`}
    >
      <p>Your browser does not support the audio element.</p>
    </audio>
  ) : (
    <audio
      id="audio"
      preload="auto"
      ref={audioRef}
      src={`http://icecast.radiofrance.fr/fip-midfi.mp3`}
      autoPlay={true}
    >
      <p>Your browser does not support the audio element.</p>
    </audio>
  );
};
